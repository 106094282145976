import React from 'react'
import { Layout, Breadcrumbs } from '@common'
import { CategoriesSection, BlogSection, AttentionSection } from '@sections'
import './styles/categories.scss'
 
const Categories = ({ pageContext }) => {
	const { page, categories, posts } = pageContext
	return (
		<Layout seo={pageContext.page.seo}
		siteMetaData={pageContext.page.siteMetaData}>
			<AttentionSection height={350} title={page.title}/>
			<div className='categories'>
				<div className='container'>
					<Breadcrumbs
						elements={[
							{ label: 'Kategorie', url: '/categories/' },
						]}
					/>
					<CategoriesSection title={'Kategorie'} categories={pageContext.categories} slider={false} />
				</div>
				<BlogSection posts={posts} slider={true} />
				{/*
				<CallTherapistSection />
				<NewsletterSection />
				*/}

			</div>
		</Layout>
	)
}
export default Categories
